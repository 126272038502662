import { post } from '@/utils/request'

//获取目录
export function getList(data) {
    return post('psi/sell/commodity', data)
}
//修改
export function getEdit(data) {
    return post('psi/sell/editCommodityStore', data)
}
//查询
export function getList2(data) {
    return post('psi/sell/inventory', data)
}
// 详情
export function getInfo(data) {
    return post('psi/sell/commodityInfo', data)
}
//商品id
export function getOption(data) {
    return post('psi/sell/classList', data)
}
//状态
export function getState(data) {
    return post('/psi/Sell/updateCommodityStatus', data) 
}
//项目列表
export function getOption2(data) {
    return post('admin/merchant/section', data)
}

export function shopList(data) {
    return post('/admin/merchant/listMerchant', data)
}
//分类下拉
export function getClass(data) {
    return post('/admin/goods/goodsClassList', data)
}

