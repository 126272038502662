<template>
  <div class="user">
    <all-log :dialogLog="dialogLog" />
    <!-- 修改用户 -->
    <el-dialog
      :visible.sync="dialogFormVisible"
      @close="userClose(form)"
      :append-to-body="true"
    >
      <div slot="title" class="dialog-footer">
        <!-- <div class="title" v-show="openState">售卖商品查询</div> -->
        <div class="title" v-show="!openState">编辑售卖商品</div>
      </div>
      <!-- <el-dialog
        width="30%"
        title="查询结果"
        :visible.sync="innerVisible"
        append-to-body
      >
        <el-table :data="tableData2" style="width: 100%">
          <el-table-column prop="code_name" label="商品编号" width="180">
          </el-table-column>
          <el-table-column prop="state" label="状态">
            <template slot-scope="scope">
              <el-tag v-show="scope.row.state == 1">未售卖</el-tag>
              <el-tag v-show="scope.row.state == 2">已售卖</el-tag>
            </template>
          </el-table-column>
        </el-table>
      </el-dialog> -->
      <el-form :rules="editRules" :model="form" v-if="!openState">
        <el-form-item
          :label-width="formLabelWidth"
          label="排序"
          v-if="form.editType === 'commodity'"
        >
          <el-input v-model="form.sort" type="number" min="0"></el-input>
        </el-form-item>
        <el-form-item
          v-if="form.editType === 'commodity'"
          prop="rec"
          :label-width="formLabelWidth"
          label="是否推荐商品"
        >
          <el-switch
            v-model="form.rec"
            :active-value="1"
            :inactive-value="0"
            active-color="#13ce66"
            inactive-color="#ff4949"
          />
        </el-form-item>
        <el-form-item
          v-if="form.editType === 'commodity'"
          prop="units"
          :label-width="formLabelWidth"
          label="单位"
        >
          <el-select style="width:100%" v-model="form.units" placeholder="单位">
            <el-option
              v-for="item in unit"
              :key="item.name"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="form.editType === 'spec'"
          label="价格"
          :label-width="formLabelWidth"
          prop="price"
        >
          <el-input
            type="number"
            min="0.01"
            v-model="form.price"
            placeholder="价格"
          ></el-input>
        </el-form-item>
        <el-form-item
          v-if="form.editType === 'spec'"
          label="库存"
          prop="store_num"
          :label-width="formLabelWidth"
        >
          <el-input
            v-model="form.store_num"
            min="1"
            type="number"
            placeholder="库存"
          ></el-input>
        </el-form-item>
        <el-form-item
          v-if="form.editType === 'spec'"
          label="是否换购商品"
          prop="is_purse"
          :label-width="formLabelWidth"
        >
          <el-switch
            v-model="form.is_purse"
            :active-value="1"
            :inactive-value="0"
            active-color="#13ce66"
            inactive-color="#ff4949"
          />
        </el-form-item>
        <el-form-item
          v-if="form.editType === 'spec' && form.is_purse === 1"
          label="换购价格"
          prop="purse_price"
          :label-width="formLabelWidth"
        >
          <el-input
            v-model="form.purse_price"
            min="0"
            type="number"
            placeholder="换购价格"
          ></el-input>
        </el-form-item>
        <el-form-item
          prop="parentid"
          label="商品分类"
          :label-width="formLabelWidth"
          v-if="form.editType === 'commodity'"
        >
          <el-cascader
            style="width:100%"
            placeholder="请选择商品分类"
            :options="classifyOpt"
            :props="optionProps"
            v-model="form.parentid"
            filterable
          >
          </el-cascader>
        </el-form-item>
      </el-form>
      <!-- 表二 -->
      <!-- <el-form :model="form" v-if="openState">
        <el-form-item label="商品id" :label-width="formLabelWidth">
          <el-cascader
            :options="options"
            :props="optionProps"
            v-model="demand.mp_id"
            filterable
            clearable
          >
          </el-cascader>
        </el-form-item>

        <el-form-item label="查询状态" :label-width="formLabelWidth">
          <el-radio v-model="demand.state" :label="1">未租售</el-radio>
          <el-radio v-model="demand.state" :label="2">已租售</el-radio>
        </el-form-item>
      </el-form> -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <!-- <el-button type="primary" @click="setAdd" v-show="openState"
          >查 询</el-button
        > -->
        <el-button type="primary" @click="setEdit" v-show="!openState"
          >修 改</el-button
        >
      </div>
    </el-dialog>

    <el-dialog
      :visible.sync="dialogVisible"
      :width="openState ? '30%' : '50%'"
      :append-to-body="true"
    >
      <div slot="title" class="dialog-footer">
        <!-- <div class="title" v-show="openState">删除</div> -->
        <div class="title" v-show="!openState">查看详情</div>
      </div>
      <span v-show="openState">{{ userdelinfo.mp_name }}</span>
      <el-descriptions v-show="!openState" :column="1" border>
        <el-descriptions-item label="排序">{{
          info.sort
        }}</el-descriptions-item>
        <el-descriptions-item label="单位">{{
          info.units
        }}</el-descriptions-item>
      </el-descriptions>
    </el-dialog>
    <!-- 面包屑导航 -->
    <el-card>
      <div class="menu-box">
        <h2>售卖仓库列表</h2>
        <div class="box-bd">
          <!-- 头 -->
          <el-row type="flex" :gutter="20">
            <el-col :span="2"
              ><el-tag type="info" effect="dark">条件查询</el-tag></el-col
            >
            <el-col :span="5">
              <el-select
                size="medium"
                @change="catalogue"
                v-model="getInfo.app_id"
                placeholder="请选择店铺"
                clearable
              >
                <el-option
                  v-for="item in shopList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="5">
              <el-cascader
                size="medium"
                @change="handleClassChange"
                placeholder="请选择商品分类"
                :options="classifyOpt"
                :props="optionProps"
                v-model="getInfo.class_arr"
                filterable
                clearable
              >
              </el-cascader>
            </el-col>
            <el-col :span="6">
              <el-input
                size="medium"
                placeholder="请输入商品名"
                v-model="getInfo.goods_name"
              >
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click="catalogue"
                ></el-button>
              </el-input>
            </el-col>
            <el-col :span="6">
              <!-- <el-button type="primary" @click="addOpen()">查询</el-button> -->
              <el-button type="warning" @click="dialogOpen">查看日志</el-button>
            </el-col>
          </el-row>
          <!-- 表格 -->
          <el-table
            default-expand-all
            v-loading="loading"
            ref="multipleTable"
            :data="tableData"
            row-key="id"
            :expand-row-keys="expands"
            tooltip-effect="dark"
            style="width: 100%"
            :row-class-name="getRowClass"
            class="sell-table"
          >
            <el-table-column type="expand">
              <template slot-scope="scope">
                <!-- {{ scope.row.spec }} -->
                <!-- <el-tag v-if="scope.row.list.length === 0"
                  >该商品暂无规格</el-tag
                > -->
                <el-table
                  style="font-size:14px!important;"
                  :data="scope.row.list"
                  :row-class-name="getInnerRowClass"
                  class="expand-table"
                  v-if="scope.row.list.length !== 0"
                >
                  <el-table-column width="140" label="hhh"></el-table-column>
                  <el-table-column
                    prop="key_name"
                    label="规格名"
                    width="150"
                  ></el-table-column>
                  <el-table-column
                    width="170"
                    prop="shop_name"
                    label="所属店铺"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="store_num"
                    label="库存"
                  ></el-table-column>
                  <el-table-column label="价格" prop="price"></el-table-column>
                  <el-table-column label="单位" prop="units"></el-table-column>
                  <el-table-column
                    prop="class"
                    width="100"
                    label="分类"
                  ></el-table-column>
                  <el-table-column prop="acc_state" label="商品类型">
                    <template slot-scope="scope">
                      <el-tag v-show="scope.row.acc_state == 0">商品</el-tag>
                      <el-tag v-show="scope.row.acc_state == 1">配件</el-tag>
                    </template>
                  </el-table-column>
                  <el-table-column width="120" label="是否上架">
                    <template slot-scope="scope">
                      <el-switch
                        @change="changeState(scope.row, 'spec ')"
                        v-model="scope.row.status"
                        active-color="#13ce66"
                        inactive-color="#ff4949"
                        :active-value="1"
                        :inactive-value="0"
                      >
                      </el-switch>
                    </template>
                  </el-table-column>
                  <el-table-column label="操作" width="190">
                    <template slot-scope="scope">
                      <el-button
                        size="mini"
                        type="warning"
                        icon="el-icon-edit"
                        @click="editOpen(scope.row, 'spec')"
                        >编辑</el-button
                      >
                      <el-button
                        size="mini"
                        icon="el-icon-search"
                        @click="infoOpen(scope.row, 'spec')"
                        >详情</el-button
                      >
                    </template>
                  </el-table-column>
                </el-table>
              </template>
            </el-table-column>
            <el-table-column
              prop="mp_name"
              width="120"
              label="商品名称"
            ></el-table-column>
            <el-table-column width="130" label="规格名称"></el-table-column>
            <el-table-column
              width="180"
              prop="shop_name"
              label="所属店铺"
            ></el-table-column>
            <el-table-column label="库存">
              <template slot-scope="scope">
                <!-- 如果是商品 商品下无属性 -->
                <div v-if="scope.row.list.length === 0">
                  {{ scope.row.inventory }}
                </div>
                <!-- 是商品 且有属性 -->
                <div
                  v-else-if="scope.row.list.length !== 0 && scope.row.inventory"
                ></div>
                <!-- 是属性 -->
                <div v-else>{{ scope.row.store_num }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="price" label="价格"></el-table-column>
            <el-table-column prop="units" label="单位"></el-table-column>
            <el-table-column
              prop="class"
              width="100"
              label="分类"
            ></el-table-column>
            <!-- <el-table-column prop="acc_state" label="类型">
              <template slot-scope="scope">
                <el-tag v-show="scope.row.acc_state == 0">商品</el-tag>
                <el-tag v-show="scope.row.acc_state == 1">配件</el-tag>
              </template>
            </el-table-column> -->
            <el-table-column label="推荐" width="120">
              <template slot-scope="scope">
                <el-tag v-show="scope.row.rec == 0">否</el-tag>
                <el-tag type="success" v-show="scope.row.rec == 1"
                  >推荐商品</el-tag
                >
              </template>
            </el-table-column>
            <el-table-column label="是否上架" width="120">
              <template slot-scope="scope">
                <el-switch
                  @change="changeState(scope.row, 'commodity')"
                  v-model="scope.row.status"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  :active-value="1"
                  :inactive-value="2"
                >
                </el-switch>
              </template>
            </el-table-column>

            <el-table-column label="操作" width="190">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="success"
                  icon="el-icon-edit"
                  @click="editOpen(scope.row, 'commodity')"
                  >编辑</el-button
                >
                <el-button
                  size="mini"
                  icon="el-icon-search"
                  @click="infoOpen(scope.row, 'commodity')"
                  >详情</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页 -->
          <el-pagination
            @current-change="pageChange"
            :pager-count="11"
            @size-change="sizeChange"
            :page-sizes="[7, 15, 20]"
            layout="total, sizes, prev, pager, next"
            :page-size="getInfo.limit"
            background
            :total="exp.num"
          >
          </el-pagination>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import {
  getList,
  getEdit,
  getOption,
  getList2,
  getInfo,
  getState,
  shopList,
} from '@/api/psi/sell.js'
import { allUnit } from '@/api/psi/unit.js'

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},

  data() {
    //这里存放数据
    return {
      expands: [],
      classifyOpt: [],

      unit: [],
      dialogLog: {
        state: false,
      },

      info: '',
      spacer: '\u00a0\u00a0\u00a0',
      openState: true,
      innerVisible: false,
      //配置
      optionProps: {
        value: 'id',
        label: 'name',
        children: 'son',
      },
      //总页数
      exp: {
        count: '',
        num: 0,
      },
      //当前页码
      getInfo: {
        //搜索框
        goods_name: '',
        class_arr: [],
        class_id: '',
        app_id: '',
        page: 1,
        page_code: 1,
        limit: 7,
      },
      //导航
      nav: [],
      demand: {
        mp_id: [],
        state: 1,
      },
      //单选列表
      radiolist: [
        {
          id: 1,
          name: '一级',
        },
        {
          id: 2,
          name: '二级',
        },
      ],
      //上级列表
      options: [],
      options2: [],
      getNav: [],
      //即将删除的信息
      userdelinfo: {},
      // 添加信息表单
      form: {
        purse_price: '',
        is_purse: '',
        price: '',
        store_num: '',
        sort: 0,
        units: '',
        rec: 0,
        parentid: '',
      },
      //表单名称长度
      formLabelWidth: '120px',
      //控制弹窗删除
      dialogVisible: false,
      //控制弹窗--新增修改
      dialogFormVisible: false,
      //搜索框
      inputSearch: '',
      //表格数据
      tableData: [],
      tableData2: [],
      loading: false,
      editRules: {
        price: [{ required: true, message: '请输入价格', trigger: 'blur' }],
        store_num: [{ required: true, message: '请输入库存', trigger: 'blur' }],
      },
      shopList: [],
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    async getOptions() {
      const { data: res } = await shopList()

      this.shopList = res.data
      this.getInfo.app_id = res.data[0].id
      let formdata = new FormData()
      formdata.append('app_id', res.data[0].id)
      const { data: res1 } = await getOption(formdata)
      this.classifyOpt = res1.data

      // this.handleClassOpt(this.classifyOpt)
    },
    handleClassOpt(opt) {
      let level = 1
      opt.forEach((item) => {
        if (item.sub && item.sub.length > 0) {
          // 不为空
          this.handleClassOpt(item.sub)
          level++
        } else {
          delete item['sub']
        }
      })
      //  console.log(opt)
      this.classifyOpt = opt
    },
    async handleStoreChange() {},
    getRowClass(row, rowIndex) {
      //console.log(row)

      if (row.row.list.length === 0) {
        // console.log('000')
        return 'row-expand-cover'
      }
      if (row.row.inventory_balance === 1) {
        return 'row-red'
      }
    },
    getInnerRowClass(row, rowIndex) {
      //  console.log(row)
      if (row.row.inventory_balance === 1) {
        return 'row-red'
      }
    },
    dialogOpen() {
      this.dialogLog.state = true
    },
    async handleClassChange() {
      console.log(this.getInfo.class_arr)
      this.loading = true
      let len = this.getInfo.class_arr.length

      let arr = this.getInfo.class_arr.sort()
      if (this.getInfo.class_arr.length !== 0) {
        this.getInfo.class_id = this.getInfo.class_arr[len - 1]
      } else {
        this.getInfo.class_id = ''
      }
      let info = await getList(this.getInfo)

      this.loading = false

      this.tableData = info.data.data
      this.exp.num = info.data.exp.num
      this.exp.count = info.data.exp.count
    },
    //修改每页数量
    sizeChange(value) {
      console.log(value)
      this.getInfo.limit = value
      this.catalogue()
    },
    async changeState(row, data) {
      console.log(data)
      // let info = await getState({ id: row.id })
      // this.judge(info)
      if (row.status === 1) {
        // 即将禁用
        this.warningText = '此操作将上架该商品，是否继续?'
      } else if (row.status === 0 || row.status === 2) {
        // 即将开启
        this.warningText = '此操作将下架该商品，是否继续?'
      }
      if (data === 'commodity') {
        //商品
        this.$confirm(`${this.warningText}`, {
          type: 'warning',
        })
          .then(async (_) => {
            let info = await getState({ commodity_id: row.id, spec_id: '' })
            this.judge(info)
            // this.getDepartmentList()
            done()
          })
          .catch((_) => {
            this.catalogue()
          })
      } else {
        this.$confirm(`${this.warningText}`, {
          type: 'warning',
        })
          .then(async (_) => {
            let info = await getState({ commodity_id: '', spec_id: row.id })
            this.judge(info)
            // this.getDepartmentList()
            done()
          })
          .catch((_) => {
            this.catalogue()
          })
      }
      //   this.expands = []
      //   this.expands.push(row.id)
    },
    //分页切换
    pageChange(value) {
      this.getInfo.page_code = value
      this.catalogue()
    },
    //打开添加
    addOpen() {
      this.openState = true
      this.dialogFormVisible = true
    },
    //打开编辑
    editOpen(row, data) {
      console.log(row)
      this.openState = false
      this.dialogFormVisible = true
      this.form.price = row.price
      if (data === 'commodity') {
        this.form.editType = 'commodity'
        // // 编辑商品
        // this.form.store_num = row.inventory
        this.form.commodity_id = row.id
        this.form.units = row.units
        // this.form.parentid = row.parentid
        this.classifyOpt.forEach((item) => {
          if (item.son) {
            item.son.forEach((i) => {
              if (i.id == row.parentid) {
                this.form.parentid = [i.pid, row.parentid]
              }
            })
          }
        })
        this.form.sort = row.sort
        this.form.rec = row.rec
      } else {
        //编辑规格
        this.form.editType = 'spec'
        this.form.commodity_id = row.commodity_id
        this.form.store_num = row.store_num
        this.form.spec_id = row.id
        this.form.is_purse = row.is_purse
        this.form.purse_price = row.purse_price
      }

      //   if (row.list && row.list.length !== 0) {

      //   }
      console.log(this.form)
    },

    async infoOpen(row, data) {
      console.log(row)
      this.openState = false
      //let info = await getInfo({ id: row.id })
      //this.info = info.data.data
      if (data === 'commodity') {
        let info = await getInfo({ commodity_id: row.id, spec_id: '' })
        this.info = info.data.data
        this.dialogVisible = true
      } else {
        let info = await getInfo({
          commodity_id: row.commodity_id,
          spec_id: row.id,
        })
        this.dialogVisible = true
      }
    },
    //编辑
    async setEdit() {
      console.log(this.form)
      this.form.sort = Number(this.form.sort)
      if (this.form.flag) {
        this.form.spec_id = ''
      }
      this.form.parentid = this.form.parentid[this.form.parentid.length - 1]
      delete this.form['editType']
      let info = await getEdit(this.form)
      this.judge(info)
    },
    //删除打开
    delOpen(row, form) {
      this.openState = true
      this.dialogVisible = true
      this.userdelinfo = row
      console.log(this.userdelinfo)
    },
    //搜索事件
    search(e) {
      console.log('搜索信息: ' + e)
    },
    //添加事件--提交事件
    async setAdd(e) {
      let form = this.demand
      form.mp_id = form.mp_id[2]
      let info = await getList2(form)
      this.tableData2 = info.data.data
      this.innerVisible = true
    },
    judge(info) {
      if (info.data.code == 1) {
        this.$message({
          message: info.data.message,
          type: 'success',
        })
        this.dialogFormVisible = false
        this.dialogVisible = false
        this.catalogue()
      } else {
        this.$message({
          showClose: true,
          message: info.data.message,
          type: 'error',
        })
        return
      }
    },
    //添加事件 退出弹窗
    userClose(e) {
      this.form = {
        price: '',
        store_num: '',
        sort: 0,
        rec: 0,
        units: '',
        parentid: '',
        purse_price: '',
        is_purse: '',
      }
      console.log('close')
    },
    //目录接口
    async catalogue() {
      this.loading = true
      let len = this.getInfo.class_arr.length

      let arr = this.getInfo.class_arr.sort()
      if (this.getInfo.class_arr.length !== 0) {
        this.getInfo.class_id = this.getInfo.class_arr[len - 1]
      }
      let info = await getList(this.getInfo)

      this.loading = false

      this.tableData = info.data.data
      this.exp.num = info.data.exp.num
      this.exp.count = info.data.exp.count
      //   this.getInfo.count = info.data.exp.count

      let formdata = new FormData()
      formdata.append('app_id', this.getInfo.app_id)
      const { data: res1 } = await getOption(formdata)
      console.log(res1)
      this.classifyOpt = res1.data
    },
    async getUnit() {
      const { data: res } = await allUnit()
      console.log(res.data)
      this.unit = res.data
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.getOptions().then(() => {
      this.catalogue()
      this.getUnit()
    })
  },
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
}
</script>

<style scoped>
.page {
  margin-top: 20px;
}
.title {
  font-size: 18px;
  font-weight: bold;
}
.set-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.set-page-left {
  color: #0000006d;
  font-size: 14px;
  font-weight: 400;
}
.sell-table >>> .row-expand-cover .cell .el-table__expand-icon,
.sell-table >>> .row-expand-cover + tr {
  display: none !important;
}
/* .sell-table >>> .row-red {
  background-color: red !important;
}
.expand-table >>> .row-red {
  background-color: orange !important;
} */
.expand-table >>> .el-table__header {
  display: none !important;
}
.expand-table >>> .expanded {
  border-bottom: 0 !important;
}
</style>
